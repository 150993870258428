.api-book {
    border: solid 3px #FBA100;
    padding: 20px;
    margin: 20px;
    list-style: none;
}

.api-book .Add-book-button {
    display: block;
    text-align: center;
    margin:15px auto;
    color: black;
    background-color: #8C8AB7;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:150px;
    height:50px;
}

.api-book .Description-button {
    display: block;
    text-align: center;
    margin:15px auto;
    color: black;
    background-color: #6BBAA7;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:150px;
    height:35px;
    padding-top:10px;
    cursor: pointer;
}

.api-book .Add-book-button:hover {
    background-color:#FBA100;
}

.api-book .Description-button:hover {
    background-color:#FBA100;
}

.api-book img {
    margin: 0 auto;
    width:50%;
    margin:15px 25%;
    height:auto;
}

.api-book h3 {
    display:block;
    text-align:center;
    font-size:28px;
}

.api-book h4 {
    text-align:center;    
    font-size:22px; 
    margin:5px;
}

.api-book h5 {
    text-align:center;    
    font-size:20px;
    margin:5px;
}

.api-book-content {
    cursor: pointer;
    line-height:25px;
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
    border-bottom: solid 1px #f2f2f2;
    border-radius: 0 0 5px 5px;
    padding: 15px;
    }

@media only screen and (min-width: 600px) {
    .api-book {
        display:flex;
        flex-direction:column;
        max-width:70%;
        margin: 10px auto 10px auto;
    }

    .api-book .Add-book-button {
        font-size: 16px;
        width:130px;
    }
    
    .api-book .Description-button {
        font-size: 16px;
        width:130px;
    }

    .api-book img {
        width:35%;
        height:auto;
        margin:5% 32%;
    }
    
    .api-book .buttons {
        display:block;
    }
}

@media only screen and (min-width: 1200px) {
    .api-book img {
        margin:5% 32%;
    }
}