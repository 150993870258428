.AddNotePage {
    max-width: 80%;
    display:block;
    padding: 15%;
}

.AddNoteForm {
    border:1px solid #FBA100;
    padding:20px;
}

.AddNotePage h2 {
    text-align:center;
    font-size: 22px;
}

.AddNotePage .Add-note-button {
    display: block;
    text-align: center;
    margin:0 auto 15px auto;
    color: black;
    background-color: #8C8AB7;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:200px;
    height:55px;
    padding:15px 0px 15px 0px;
    cursor: pointer;
}

.Add-note-subtitle {
    text-align:center;
    letter-spacing: 2px;
}

.AddNotePage .Cancel-add-note-button {
    display: block;
    text-align: center;
    margin:0 auto 0 auto;
    color: black;
    background-color: #FF3737;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:200px;
    height:42px;
    padding:0px 0px 15px 0px;
}

.AddNotePage .Add-note-button:hover {
    background-color:#FBA100;
}

.AddNotePage .Cancel-add-note-button:hover {
    background-color:#FBA100;
}

.AddNotePage Input {
    width:200px;
    margin:15px;
}

.AddNotePage Textarea {
    width:200px;
    margin:15px;
}

@media only screen and (min-width: 600px) {
    .AddNotePage .Cancel-add-book-button {
        padding-top:15px;
        height:38px;
    }

    .AddNotePage {
        max-width: 40%;
        margin: 5% auto;
        padding:50px;
    }

    .AddNotePage Input {
        width:80%;
        padding-left:30px;
    }
    
    .AddNotePage Textarea {
        width:90%;
    }
}

@media only screen and (min-width: 992px) {
    .AddNotePage .Cancel-add-book-button {
        padding-top:15px;
        height:38px;
    }
}