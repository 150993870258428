.BookPage {
    max-width:80%;
    margin: 40px auto 10px auto;
}

.BookPage_title {
    font-size:30px;
    margin:40px;
    text-align:center;
    max-width: 80%;
    border:none;
}

.BookPage_note-title {
    font-weight:bold;
}

.BookPage_content {
    border:3px solid #6BBAA7;
    font-size:20px;
    text-align:center;
    padding:10px;
    line-height:30px;
}

.BookPage .Add-note-button {
    display: block;
    text-align: center;
    margin:15px auto;
    color: black;
    background-color: #8C8AB7;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:200px;
    height:45px;
    padding:15px 0px 0px 0px;
}

.BookPage .Add-note-button:hover {
    background-color: #FBA100;
}

@media only screen and (min-width: 600px) {
    .BookPage {
        max-width:60%;
        margin: 40px auto 10px auto;
    }

    .BookPage_title {
        font-size:30px;
        font-weight:bold;
        margin: 20px auto 30px auto;
        text-align:center;
        max-width: 80%;
        border:none;
    }   

    .BookPage_content {
        margin: 20px 40px;
        font-size:16px;
        line-height: 25px;
        text-align:center;
        padding:15px;
    }

    .BookPage .Add-note-button {
        margin:0px auto;
        font-size:16px;
        width:150px;
        height:35px;
    }
}

@media only screen and (min-width: 600px) {
    
    .BookPage_title {
        font-size:30px;
    }

    .BookPage_description {
        font-size:20px;
    }
}

@media only screen and (min-width: 1200px) {
    
    .BookPage_title {
        font-size:40px;
    }

    .BookPage_description {
        font-size:25px;
    }
}