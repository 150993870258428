.LibraryPage {
    display:block;
}

.LibraryPage h2 {
    font-size:40px;
    margin-top:50px;
    text-align: center;
}

.LibraryPage .input {
    text-align: center;
}

.red {
    color: #631444;
}