* {
    font-family: 'Roboto', sans-serif;
}

body {
    margin:0;
}

.App_header {
    background-image: url("https://i.imgur.com/KYCL07Y.jpg");
    background-size: cover;
    display:block;
    font-size: 30px;
    font-style: none;
    text-decoration: none;
    color: #cccccc;
    height: 200px;
    padding: 20px;
}

.App_header a {
    text-decoration: none;
    font-family: 'Libre Baskerville', serif;
    color: #cccccc;
}

.App_header h1 {
    letter-spacing: 2px;
    font-size: 55px;
    margin: 25px;
    color: #cccccc;
    text-align: center;
    margin-top:40px;
}

.App_header h2 {
    font-size: 20px;
    margin: 0;
    color: #cccccc;
    text-align: center;    
    margin-bottom: 50px;
}