.SearchError a {
    background-color: #FF3737;
    display: block;
    text-align: center;
    margin:0 auto 0 auto;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    color: black;
    font-size: 16px;
    width:200px;
    height:30px;
    padding-top:15px;
}

.SearchPage h2 {
    padding:25px;
    text-align: center;
    font-size: 30px;
}

@media only screen and (min-width: 600px) {
    .SearchPage {
        max-width: 70%;
        margin: 10px auto 10px auto;
    }
}

@media only screen and (min-width: 992px) {
    .SearchPage {
        max-width: 50%;
        margin: 10px auto 10px auto;
    }
}