.SignUpForm {
    display: block;
    max-width: 50%;
    border: 3px solid #8C8AB7;
    border-radius:5%;
    margin: 50px auto 50px auto;
    padding: 50px;
}

Input {
    margin: 25px 0;
    height: 40px;
    width: 90%;
}

label {
    margin-top:15px;
    font-size: 20px;
    text-align:center;
}

.SignUpForm_Submit {
    display: block;
    text-align: center;
    padding: 10px;
    color: black;
    background-color: #6BBAA7;
    text-decoration: none;
    border-radius:5%;
    width:140px;
    font-size:20px;
    margin: 10px auto 10px auto;
}

.SignUpForm_Submit:hover {
    background-color:#8C8AB7;
}

@media only screen and (min-width: 600px) {
    .SignUpForm {
        max-width: 40%;
    }
}

@media only screen and (min-width: 992px) {
    .SignUpForm {
        max-width: 30%;
    }
}