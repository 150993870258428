.customize-results {
    display:flex;
    justify-content:space-between;
    margin:0 0 0 15%;
}

.customize-results select {
    font-size: 18px;
}

.Sort label {
    padding: 30px;
}

@media only screen and (min-width: 992px) {
    .Sort {
        margin-left: 10%;
    }
}