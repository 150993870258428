.searchTerms {
    padding: 40px;
    border: 1px solid #FBA100;
    margin: 40px;
}

.searchBox .searchSubmit {
    display: block;
    text-align: center;
    margin:0 auto 15px auto;
    color: black;
    background-color: #8C8AB7;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:100px;
    height:40px;
    cursor: pointer;
}

.searchBox .searchSubmit:hover {
    background-color:#FBA100;
}