.single-book {
    border: solid 3px #FBA100;
    padding: 20px;
    margin: 20px;
}

ul {
    list-style: none;
    padding: 15px;
}

.single-book .Book-options-button {
    display: block;
    text-align: center;
    margin: 10px auto 10px auto;
    color: black;
    background-color: #8C8AB7;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    height:40px;
    padding:18px 0 0 0;
    width:200px;
    cursor: pointer;
}

.single-book .Book-view-notes-button {
    display: block;
    text-align: center;
    margin: 10px auto 10px auto;
    color: black;
    background-color: #FBA100;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    font-size:20px;
    width:200px;
    height:40px;
    padding:18px 0px 0px 0px;
}

.buttons .Book-description-button {
    background-color: #6BBAA7;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    color: black;
    font-size: 20px;
    width:200px;
    height:42px;
    padding-top:15px;
    margin: 10px auto 10px auto;
}

.buttons .Book-remove-button {
    background-color: #FF3737;
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    color: black;
    font-size: 20px;
    width:200px;
    height:55px;
    padding:15px 0px;
    cursor: pointer;
    margin: 10px auto 10px auto;
}

.single-book .Book-options-button:hover {
    background-color:#4EB34A;
}

.single-book .Book-remove-button:hover {
    background-color:#4EB34A;
}

.single-book .Book-description-button:hover {
    background-color:#4EB34A;
}

.single-book .Book-view-notes-button:hover {
    background-color:#4EB34A;
}


.single-book img {
    display:block;
    margin: 15px auto;
    width:60%;
    height:auto;
}

.single-book h3 {
    display:block;
    text-align:center;
    font-size:28px;
}

.single-book h4 {
    text-align:center;    
    font-size:22px; 
    margin:5px;
}

.single-book h5 {
    text-align:center;    
    font-size:20px;
    margin:5px;
}

.single-book p {
    text-align:center;    
    font-size:18px;
    margin:5px;
}

@media only screen and (min-width: 600px) {
    .single-book {
        position:relative;
        left:8%;
        display:inline-block;
        width:70%;
    }

    .single-book img {
        display:block;
        width:20%;
        height:auto;
    }

    .single-book .buttons {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 480px;
        height: 100px;
        align-content: space-around;
    }

    .single-book .Book-options-button {
        width:80px;
        height:30px;
        font-size:14px;
        padding:15px 0 0 0;
    }
    
    .single-book .Book-remove-button {
        width:80px;
        font-size:14px;
        height:45px;
        padding:5px;
    }
    
    .single-book .Book-description-button {
        width:80px;
        font-size:14px;
        height:30px;
    }
    
    .single-book .Book-add-note-button {
        width:80px;
        font-size:14px;
        height:30px;
    }
    
    .single-book .Book-view-notes-button {
        width:80px;
        font-size:14px;
        height:33px;
        padding:14px 0 0 0;
    }
}

@media only screen and (min-width: 1000px) {
    .single-book {
        position:relative;
        left:18%;
        display:inline-block;
        width:60%;
    }

    .single-book .buttons {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        height: 100px;
        align-content: space-around;
    }

    .single-book .Book-options-button {
        width:100px;
        height:32px;
        font-size:16px;
        padding: 13px 0 0 0;
    }
    
    .single-book .Book-remove-button {
        width:100px;
        font-size:16px;
        height:45px;
        padding:5px;
    }
    
    .single-book .Book-description-button {
        width:100px;
        font-size:16px;
        height:30px;
    }
    
    .single-book .Book-add-note-button {
        width:100px;
        font-size:16px;
        height:30px;
    }
    
    .single-book .Book-view-notes-button {
        width:100px;
        font-size:16px;
        height:30px;
    }
}