.Landing_Page * {
    box-sizing: border-box;
}

.Landing_Page a {
    text-decoration: none;
    color: #83677B;
}

.Landing_Page p {
    margin:0;
    padding: 20% 20%;
    font-size: 20px;
    background-color: #F1EDF1;
    color: black;
}

.Landing_Page img {
    width: 40px;
    margin: 15px;
}

.contact {
    margin:0;
    text-align: center;
    background-color: #8C8AB7;
    color: #F1EDF1;
}

@media only screen and (min-width: 992px) {
    .Landing_Page p {
        padding: 5% 20%;
    }
}