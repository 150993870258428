.BookNotesPage h2 {
    font-size: 30px;
    text-align:center;
    margin:50px 15px 0px 15px;
}

.BookNotesPage h3 {
    text-align:center;
    margin:15px;
}

.Add-book-note-button p {
    text-align:center;
    margin:0;
}

.Notes-subtitle {
    letter-spacing: 2px;
}

.Add-book-note-button {
    background-color: #8C8AB7;
    display: block;
    text-align: center;
    margin:0 auto 0 auto;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    color: black;
    font-size: 20px;
    width:150px;
    height:42px;
    padding-top:15px;
}

.Add-book-note-button:hover {
    background-color:#C2B8FF;
}

.Back-to-book-button {
    background-color: #FBA100;
    display: block;
    text-align: center;
    margin:15px auto 0px auto;
    text-decoration: none;
    border-radius:5%;
    border: 1px black;
    color: black;
    font-size: 20px;
    width:150px;
    height:45px;
    padding: 15px 0 0 0;
}

.Back-to-book-button p {
    margin:0;
}

.Back-to-book-button:hover {
    background-color:#C2B8FF;
}

@media only screen and (min-width: 600px) {
    .BookNotesPage {
        max-width: 50%;
        margin:0px auto;
    }
}

@media only screen and (min-width: 992px) {
    .BookNotesPage {
        max-width: 40%;
        margin:0px auto;
    }

    .Add-book-note-button {
        font-size:18px;
        height:40px;
    }
}