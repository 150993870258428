.LibraryNav_main {
  list-style-type: none;
  padding: 0;
  max-height: 50px;
  position: fixed;
  top: 0px;
  left:0px;
}

.LibraryNav_link {
  color: #6BBAA7;
  display: inline-block;
  font-size: 20px;
  margin: 10px 10px;
  max-width: 100px;
  text-decoration: none;
}

.fa-sign-out-alt {
  color: #6BBAA7;
  display: inline-block;
  font-size: 20px;
  margin: 10px 10px;
  max-width: 100px;
  text-decoration: none;
}

.fa-sign-out-alt:hover {
  color: #FBA100;
}

.LibraryNav_link:hover {
  color: #FBA100;
}

.LibraryNav_landing ul {
  display:flex;
  justify-content: center;
  letter-spacing: 1px;
  flex-direction:row;
  background: #668F80;
  width: 100%;
  background-color:none;
  position:absolute;
  top:0px;
  left:0px;
  font-size: 18px;
  padding:0;
}

.LibraryNav_landing li {
  text-align:center;
  padding: 5px 5%;
  cursor:pointer;
}

.LibraryNav_landing li:hover {
  background-color: #DADFF7;
}

a {
  text-decoration: none;
  color: black;
}

@media only screen and (min-width: 992px) {
  .LibraryNav_landing li {
    padding: 5px 8%;
  }
}